.footer {
  padding-top: 20px;
  padding-bottom: 40px;

  .footer-text {
    display: flex;
  }

  .right {
    text-align: right;
    .top {
      height: 22px;
      padding-top: 5px;
      padding-left: 50px;
    }
  }
}