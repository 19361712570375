.qs__feedback {
   background:$feedbackBackground;
   height: calc(100% - 50px);
    &__item{
        position: absolute;
        width: calc( 100% - 15px );
        height: calc( 100% - 15px );
        max-width: calc( 100% - 15px );
        max-height: calc( 100% - 15px );
        -webkit-transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 3s;
        -moz-transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 3s;
        -o-transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 3s;
        transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 3s;
    }
    &__item-title {
		padding-bottom:10px;

    }
    &__item-headline {
        font-weight: bold;
		font-size:24px;
        line-height: 20px;
    }
    &__image-container {
        text-align: center;
		@include aspect-ratio($imageContainerWidth,$imageContainerHeight);
		background-repeat: no-repeat;
  		background-position: top center;
		background-size: 60% 60%;
    }
    &__image {
        width: 100%;
		height: auto;
        margin: 0 auto;
    }
    &__inner{
        min-width: auto;
		min-height:auto;
		padding:15px;
		width:50%;
		float:left;
		.md-button{
			margin: 0px;
			border: 1px solid $button-border;
			backgound: $button-background;
		}
    }
}



@media (min-width: 551px){
	.qs__feedback  {
		&__item-title {
			height:20%;
			padding-top:10%;
    		}
		&__item-description{
			padding-bottom:10%;
		}
		&__image-container {
    		margin-top: 10%;
		}
	}
}
@media (max-width: 550px){
	.qs__feedback  {
		&__inner{
			width:100%;
			float:none;
			text-align:center;
		}
		&__item-title {
			padding-top:20px;
			padding-bottom:20px;
    		}
		&__item-description{
			padding-bottom:20px;
		}
		&__image-container {
			@include aspect-ratio($imageContainerWidthResponsive,$imageContainerHeightResponsive);
			background-position: center center;

    	}
	}
}
