

.qe__loading{

	&__inner{
		width: auto;
	    display: table-cell;
	    vertical-align: middle;
	    text-align: center;
	    margin: 0 auto;
	}
	
	display: table;
    position: absolute;
    width: 100%;
    height:100%; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 7500;
    background: rgba( 255, 255, 255, 1 );
}