/* cok: format checkboxes in solr view correctly after bootstrap update 
   from Bootstrap v3.0.2 to Bootstrap v3.3.7 */

#topicorarticle.checkbox input[type="checkbox"] {
    float: left;
    margin-left: -5px;
    position: relative;
}

#topicorarticle.radio label, #topicorarticle.checkbox label {
    cursor: pointer;
    display: inline;
    font-weight: normal;
    margin-bottom: 0;
}

/* end: format checkboxes */

.specificajaxsearch .filter2 #sorting_filter{
	float: left;
}

.specificajaxsearch .filter2 #facet-select{
	margin-bottom: 10px;
	float: left;
	margin-right: 22px;
}

 
.foamtree-widget .foamtreevisualization.smallFoamTree {
    height: 210px;
}

/* **SOLR BROWSER MAP** */
/* .body_browsermapajax div#articleMapOverlayModal { */
/* 	height: 645px; */
/* } */

.body_browsermapajax div#articleMapOverlayModal .modal-header,
.body_browsermapcustomlayer div#articleMapOverlayModal .modal-header{
    border-bottom: none;
}

.browserlist .add-topic-bottom{
	display:none;
}

.body_browsermapajax div.mapElement,
.body_browsermapajax .sidebar_01 .topic_list div.topicList,
.body_browsermapcustomlayer div.mapElement,
.body_browsermapcustomlayer .sidebar_01 .topic_list div.topicList,
.body_browserevaluationshow div#spiderweb {
	height: 575px;
}

div#spiderweb p.noResult{
	text-align: center;
    margin-top: 33%;
}
.body_browserevaluationshow .newPostItButton {
	height: 50px;
}
.body_browserevaluationshow div.spiderweb{
	padding-left: 0px;
}
.body_browserevaluationshow div#spiderweb{
	min-width: 465px;
	max-width: 545px;
}
.body_browsermapajax .sidebar_01 > div,
.body_browsermapajax .sidebar_01 > form,
.body_browsermapcustomlayer .sidebar_01 > div,
.body_browsermapcustomlayer .sidebar_01 > form {
	margin-bottom: 0px;
}
.body_browsermapajax div#articleMapOverlayModal .modal-dialog,
.body_browsermapcustomlayer div#articleMapOverlayModal .modal-dialog {
	width:90%;
}
.body_browsermapajax .modal-backdrop,
.body_browsermapcustomlayer .modal-backdrop{
	display: none;
}
.body_browsermapajax div#main_container2 div#pager-header,
.body_browsermapcustomlayer div#main_container2 div#pager-header {
	text-align: end;
	float: right;
}
.body_browsermapajax .row.c_02,
.body_browsermapajax .row.c_03,
.body_browsermapcustomlayer .row.c_03,
.body_browsermapcustomlayer .row.c_02{
    margin-bottom: 0px;
}
div#main_container2 div#pager-header .hugeNumResult{
	font-size: 2.5em;
	color: red;
}
.body_browsermapajax div#main_container2,
.body_browsermapcustomlayer div#main_container2 {
	position: relative;
}

.body_browsermapajax .sidebar_01 .topic_list div.topicList,
.body_browsermapcustomlayer .sidebar_01 .topic_list div.topicList {
	overflow-y: scroll;
}

.solrPagination #pager span.pager-current {
    background-color: #ccc;
}
@media ( min-width : 770px) {
	.browserlist .searchList {
		min-height: 500px;
	}
}
@media screen and (max-height: 769px) {
	body.body_browsermapajax,
	body.body_browsermapcustomlayer{
   		min-height: 768px;
	}
	.body_browsermapajax div.mapElement,
	.body_browsermapajax .sidebar_01 .topic_list div.topicList,
	.body_browsermapcustomlayer div.mapElement,
	.body_browsermapcustomlayer .sidebar_01 .topic_list div.topicList {
		height: 450px;
	}
	.body_browsermapajax div#articleMapOverlayModal,
	.body_browsermapcustomlayer div#articleMapOverlayModal {
		height: 610px;
	}
		
}
@media ( max-width : 769px) {
	.body_browsermapajax .sidebar_01 .topic_list div.topicList,
	.body_browsermapajax div#articleMapOverlayModal,
	.body_browsermapcustomlayer .sidebar_01 .topic_list div.topicList,
	.body_browsermapcustomlayer div#articleMapOverlayModal {
		overflow-y: hidden;
	    height: auto; /*need to adpat it fo the responsive*/
	}
	.body_browsermapajax .sidebar_01 .topic_list,
	.body_browsermapcustomlayer .sidebar_01 .topic_list {
		margin-left: -30px;
		margin-top: 15px;
	}
}
