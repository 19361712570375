.d-res-table {
  display: table;
  margin: 0 0 1.5em 0;
  padding: 0;
  font: inherit;
  vertical-align: baseline;
  border-collapse: collapse;
  box-shadow: 1px 1px 5px #bbb;
  border: 1px solid #bbb;
}

.d-res-table-head{
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

.d-res-table-body{
  display: table-row-group;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;

  .d-res-table-row {
    &:nth-of-type(2n+1) {
      background-color: #f0f0f0;
    }
  }
}

.d-res-table-row{
  display: table-row;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;

  .d-res-table-th{
    margin: 0;
    border: 0;
    font: inherit;
    border-top: 1px solid #ccc;
    vertical-align: top;
    padding: 4px 8px;
    font-weight: bold;
    display: table-cell;

    &:not(:first-of-type){
      border-left: 1px solid #ccc;
    }
  }

  .d-res-table-td{
    margin: 0;
    border: 0;
    font: inherit;
    vertical-align: middle;
    border-top: 1px solid #ccc;
    padding: 4px 8px;

    &.bold {
      font-weight: bold;
    }

    strong {
      width: 100%;
      display: block;
    }
  }
}

@media screen and (max-width: 600px){
  .d-res-table {
    width: 100%;
  }
  .d-res-table-head {
    /* display: none; */
    text-overflow: ellipsis;
  }
  .d-res-table-tbody .d-res-table-row:nth-of-type(2n+1) {
    background-color: inherit;
  }

  .d-res-table-row {
    .d-res-table-td {
      // display: block;
      text-align: center;

      &:first-child {
        // background: #f0f0f0;
        font-weight: bold;
        font-size: 1.3em;
      }

      &:before{
        content: attr(data-th);
        display: block;
        text-align: center;
      }
    }
  }
}