.angular-ui-tree {
}

.angular-ui-tree-empty, .angular-ui-tree-dropzone {
    border: none;
    min-height: 100px;
    background-color: #E9ECEF;
}

.angular-ui-tree-empty {
    pointer-events: none;
}

.angular-ui-tree-nodes {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

.angular-ui-tree-nodes .angular-ui-tree-nodes {
    padding-left: 20px;
}

.angular-ui-tree-node, .angular-ui-tree-placeholder {
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 20px;
    line-height: 20px;
}

.angular-ui-tree-hidden {
    display: none;
}

.angular-ui-tree-placeholder {
    margin: 10px;
    padding: 0;
    min-height: 30px;
}

.angular-ui-tree-handle {
    cursor: move;
    text-decoration: none;
    font-weight: bold;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 20px;
    line-height: 20px;
}

.angular-ui-tree-drag {
    position: absolute;
    pointer-events: none;
    z-index: 999;
    opacity: .8;
}

.angular-ui-tree-drag .tree-node-content {
    margin-top: 0;
}

.tree-node-content {
    margin: 10px;
}


.angular-ui-tree-handle {
    background: #fefefe;
    border: none;
    color: #7c9eb2;
    padding: 10px 10px;
}

.angular-ui-tree-handle:hover {
    color: #438eb9;
    background: #f4f6f7;
    border-color: #dce2e8;
}

.angular-ui-tree-placeholder {
    background: #f0f9ff;
    border: 2px dashed #bed2db;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

tr.angular-ui-tree-empty {
    height:100px
}

.group-title {
    background-color: #687074 !important;
    color: #FFF !important;
}

/* --- Tree --- */
.tree-node {
    border: 1px solid #dae2ea;
    background: #f8faff;
    color: #7c9eb2;
}

.nodrop {
    background-color: #f2dede;
}

.tree-node-content {
    margin: 10px;
}
.tree-handle {
    padding: 10px;
    background: #428bca;
    color: #FFF;
    margin-right: 10px;
}

.angular-ui-tree-placeholder {
    background: #f0f9ff;
    border: 2px dashed #bed2db;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}



/**/
.angular-ui-tree.polling-tree{
	margin: 15px 0px 15px 15px;
}

.angular-ui-tree.polling-tree .angular-ui-tree-node{
	font-size: 14px !important;	
	margin: 0 !important;	
}


.angular-ui-tree.polling-tree .angular-ui-tree-node .angular-ui-tree-handle{
	padding: 5px 5px;
	cursor: pointer;
	font-weight: 100;
	background: transparent;
	border: none;
	color: #000;
}	
	
.angular-ui-tree.polling-tree .angular-ui-tree-node .angular-ui-tree-handle .sub-item-title{
	display: inline-block;
	width: calc(100% - 60px );
	height: 100%;
}

.angular-ui-tree.polling-tree .angular-ui-tree-node .angular-ui-tree-handle .item-title{
	display: inline-block;
	width: calc(100% - 55px );
	height: 100%;
}

.angular-ui-tree.polling-tree .angular-ui-tree-node .angular-ui-tree-handle.active,
.angular-ui-tree.polling-tree .angular-ui-tree-node .angular-ui-tree-handle:hover{
/*   background: #E0EAB9; */
    font-weight: bold;
}

.angular-ui-tree.polling-tree .angular-ui-tree-node .angular-ui-tree-handle .badge{
  background: orange;
}

.angular-ui-tree.polling-tree .angular-ui-tree-node .angular-ui-tree-handle .badge.forPage{
  background: red;
}

.angular-ui-tree.polling-tree .angular-ui-tree-node .angular-ui-tree-handle .badge.success{
  background: rgba(172, 201, 57, 0.7);
}