.qs{
    border: $border;
	height: 100%;
	overflow:hidden;
}

.qs__quiz {
	display: block;
    width: 100%;
    height: 100%;
    &__inner {
        margin: 0 auto;
        border: $border;
        height: 100%;
    }
    &__top {
       @extend %quizHeader;
    }
    &__top-text{
        padding: 0 8px;
        white-space: nowrap;
    }
    &__bullets{
        &.left {
            flex-grow: 1;
        }
        &--right{
            .qs__quiz__bullet{
                background: $headerBulletColor;
            }
            text-align: right;
        }
        line-height: 20px;
        padding: 0 8px;
        position: relative;
    }
    &__bullet{
        &.ng-move,&.ng-enter,&.ng-leave {
            -webkit-transition:all ease 0.35s;
            transition:all ease 0.35s;
        }
        &.ng-leave.ng-leave-active,
        &.ng-move,
        &.ng-enter {
            opacity:0;
            zoom: .5;
        }
        &.ng-leave.ng-leave-active,
        &.ng-leave{
            position: absolute;
            opacity:1;
        }
        &.ng-move.ng-move-active,
        &.ng-enter.ng-enter-active {
            opacity:1;
            zoom: 1.25;
        }
        &.fail{
            background: rgba(244,67,54,1);
        }
        &.unanswered{
            background: #ffffff;
            border: 1px solid #ccc;
        }
        display: inline-block;
        width: 8px;
        height: 8px;
        background: rgba(139,195,74,1);
        border-radius: 50%;
        margin: 0 2px;
    }
    &__content {
         @extend %quizContent;
    }
    &__item {

        &.ng-enter,&.ng-leave{
            position: absolute;
            -webkit-transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) .5s;
            -moz-transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) .5s;
            -o-transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) .5s;
            transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) .5s;
            width:100%;
        }

        &--left {
            &.ng-enter {
                &.ng-enter-active {
                    left: 0;
                }
                left: 100%;
            }
            &.ng-leave {
                &.ng-leave-active {
                    left: -100%;
                }
                left: 0;
            }
        }
        &--right {
            &.ng-enter {
                &.ng-enter-active {
                    left: 0;
                }
                left: -100%;
            }
            &.ng-leave {
                &.ng-leave-active {
                    left: 100%;
                }
                left: 0;
            }
        }

    }

    &__bottom {
         @extend %quizFooter;
    }

}
