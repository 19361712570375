.mapElement {
  width: 100%;
  height: 575px;
  border: 1px solid #ccc;
  &.creationSpace {
    height: 300px;
  }
  .leaflet-popup-content-wrapper {
    border-radius: 0;
  }
}
.browsermap {
  .autoSuggestMap {
    display: flex;
    position: absolute;
    top: 32px;
    left: 0;
    right: 0;
    z-index: 1001;
    background: #fefefe;
    border: 1px solid #ccc;
    .result {
      list-style: none;
      padding: 0;
      margin: 0;
      flex-grow: 1;
      li {
        padding: 8px 16px;
        cursor: pointer;
      }
    }
  }
  .layersControls {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    padding: 8px;
    border: 1px solid #ccc;
    .layerControl {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      margin-right: 16px;
      &>label {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        margin-right: 4px;
        i {
          margin-right: 4px;
        }
      }
      .make-switch{
        input {
          cursor: pointer;
        }
      }
    }
  }
}
.leaflet-container {
  &.mediumInput{
    height: 200px;
  }
  .leaflet-popup-content {
    .badge {
      margin-left: 4px;
    }
  }
  .leaflet-marker-icon {
    margin-top: -36px;
    margin-left: -9px;
    &:before{
      font-family: "Font Awesome\ 5 Free";
      content: '\f041';
      font-weight: 900;
      font-size: 24px;
    }
    &.cat-1 {
      color: #D63E2A;
    }
    &.cat-2 {
      color: #F2942F;
    }
    &.cat-3 {
      color: #72B026;
    }
    &.cat-4 {
      color: #38AADD;
    }
    &.cat-5 {
      color: #D051B7;
    }
    &.cat-6 {
      color: #A23336;
    }
    &.cat-7 {
      color: #00619B;
    }
    &.cat-8 {
      color: #728224;
    }
    &.cat-9 {
      color: #5B396B;
    }
    &.cat-10 {
      color: #436978;
    }
    &.cat-11 {
      color: #FF8E7F;
    }
    &.cat-12 {
      color: #B9F471;
    }
    &.cat-13{
      color: #85D9FF;
    }
    &.cat-14 {
      color: #FF8DE9;
    }
  }
}