@keyframes borderAnimationQuiz {
    0% {
        background-size: 0 3px, 3px 0, 0 3px, 3px 0;
    }
    25% {
        background-size: 100% 3px, 3px 0, 0 3px, 3px 0;
    }
    50% {
        background-size: 100% 3px, 3px 100%, 0 3px, 3px 0;
    }
    75% {
        background-size: 100% 3px, 3px 100%, 100% 3px, 3px 0;
    }
    100% {
        background-size: 100% 3px, 3px 100%, 100% 3px, 3px 100%;
    }
}

@keyframes growBox {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes shrinkBox {
  from { 
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}