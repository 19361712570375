.dito-warehouse{
  .modal-dialog.modal-lg{
    max-width: 992px;
    width: auto;
  }
  .dito-warehouse-stage{
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    flex-grow: 1;

    .dito-warehouse-header{
      height: 40px;
      border-bottom: 1px solid #ccc;
      padding: 4px 8px;
    }

    .dito-warehouse-content,
    .dito-warehouse-list{
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 8px;
      overflow-y: auto;

      .dito-warehouse-item{
        border-bottom: 1px solid #ccc;
        margin-bottom: 8px;
        display: flex;
        flex-direction: row;
        max-width: 100%;
        i.fas.fa-times{
          color: #ff4500;
        }
      }

      .dito-warehouse-item-select {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
      }

      .dito-warehouse-item-text{
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .dito-warehouse-item-icon{

      }
    }

    .dito-warehouse-footer{
      height: 40px;
      border-top: 1px solid #ccc;
      padding: 4px 8px;

      .confront{
        background-color: #28a745;

        &[disabled]{
          opacity: 0.5;
          cursor: default;
        }
      }

      .toWarehouse{
        background-color: #121282;
      }
    }
  }

  .dito-warehouse-topic-list{
    max-height: 250px;
    overflow-y: auto;
  }

  &.modal{
    .modal-dialog{
      .modal-content{
        overflow: auto;
        .modal-footer{
          .btn {
            padding: 8px;
            font-size: 14px;
            border: 1px solid;
          }
        }
      }
    }
  }

  .mb-0{
    margin-bottom: 0;
  }

  .accordion {
    .card{
      border: 1px solid #ccc;
    }
    .collapse {
      padding: 0 8px 8px 8px;
    }
    .btn{
      border-radius: 0;
      font-size: initial;
      padding:8px;
      border:none;
      border-bottom: 2px solid;
      background: transparent;
      color: #121282;
      display: flex;
      flex-direction: row;

      .title{
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .middle{
        align-items: center;
        text-align: center;
        padding: 0 16px;
      }
      .clusters{
        min-width: 150px;
        flex-grow: 1;
      }
    }
  }
}

a{
  .noteDown-button {
    cursor: pointer;
  }
}

.warehouseNavigationItem{
  position: absolute;
  right: 8px;
  top: 8px;
  &.toWarehouse{
    display: inline-flex;
    align-items: center;
    i{
      margin-left: 5px;
    }
  }
}

.warehouse-info{
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ccc;
  padding-top: 8px;
  margin-top: 8px;
  &.header{
    border-bottom: 1px solid #ccc;
    padding: 8px 0;
    margin: 8px 0;
  }
}
.warehouse-form{
  display: flex;
  flex-direction: row;
  .warehouse-left{
    display: flex;
    flex-grow: 1;
    max-width: 50%;
    padding: 8px;
  }
  .warehouse-right{
    flex-grow: 1;
    max-width: 50%;
    padding: 8px;
  }
}

.warehouseMobile {
  margin: 14px;
  &.toWarehouse{
    margin: 14px;
    display: inline-flex;
    align-items: center;
    i{
      margin-left: 5px;
    }
  }
}

/*Queries#############################################################*/
@media (min-width: 768px){

}
@media (min-width: 992px){

}
@media (min-width: 1200px){

}

@media (max-width: 992px){

}

@media (max-width: 768px){

}
@media (max-width: 600px){
  .warehouse-form{
    flex-direction: column;
    .warehouse-left{
      display: flex;
      max-width: 100%;
    }
    .warehouse-right{
      max-width: 100%;
    }
  }
}
@media (max-width: 360px){


}
