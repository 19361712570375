.mainContent {

  .synopsis {
    .line {
      margin: 8px 0;
    }
    .topicSynopsisCol_01, .topicSynopsisCol_02 {
      &>small {
        display: block;
        margin-bottom: 8px;
      }
    }
  }

  .mycontributionlist-topics, .mycontributionlist-comments {
    overflow: hidden;
    h2 {
      padding: 16px;
    }
  }

  .topicListSpiderweb {
    overflow: hidden;
    height: 575px;

    .topic_list {
      overflow: auto;
      height: calc(100% - 50px);
    }

    .topicListSpiderweb-button {
      .newPostItButton {
        height: auto
      }
    }

    .topicList {
      .postFooter {
        display: flex;
        flex-direction: row;
        .voteDisplay {
          flex-direction: column;
          flex-grow: 1;
          padding: 8px;
        }

        .postRating {
          padding: 8px;
          min-width: 64px;
          .panel {
            margin: 0;
            border: none;
            .panel-heading{
              display: none;
            }
            .panel-body{
              padding: 0;
              .ratingRangeSelectVal {
                padding: 4px;
              }
            }
          }
        }
      }
    }
  }

  .topicList {
    display: block;
    overflow: hidden;
    margin: 8px 0 16px 0;

    #formsButton {
      padding: 16px 8px;
    }

    .info {
      border-bottom: 1px solid #ccc;
      border-top: 1px solid #ccc;
      padding: 8px 0;
      margin: 8px 0;
      &>span{
        margin-right: 8px;
      }
    }

    .voteDisplay {
      display: inline-flex;
      flex-direction: column;
      .votingSelect {
        padding: 8px 16px;
      }
      .votingTokenLeft {
        flex-grow: 1;
      }
    }
  }

  .question {
    .resourceSettingslight {
      top: 0;
    }
    &.list{
      &:first-of-type {
        margin-top: 16px;
      }
      &:not(:last-of-type) {
        margin-bottom: 24px;
      }
      .rSet {
        overflow: hidden;
        .content{
          &.panel-group {
            padding: 16px;
            margin: 0;
            &.collapsing {
              padding: 0;
            }
            .panel {
              border: none;
            }
          }
        }
        .isanswered{
          .box {
            padding: 8px;
            border: 1px solid #ccc;
          }
        }
      }
    }
  }

  .comment {
    .ds-comment{
      position: relative;
      overflow: hidden;
      border: 1px solid #dedede;
      padding: 16px;
      margin-bottom: 16px;
    }
    .panel {
      margin: 0 0 8px 0;
      border: none;
    }
    .commentIcon, .icon_subcomment {
      img{
        width: 100%;
      }
    }
  }

  .answer {
    .rSet {
      overflow: hidden;
      padding: 8px;
      border: 1px solid #ccc;
      .col_01 {
        img{
          width: 100%;
        }
      }
    }
  }


  .postitimg {
    .resourceSettingslight {
      top: unset;
      bottom: 8px;
      right: 8px;
    }
    .view {
      padding: 0;
      .postHeader {
        padding: 8px;
        min-height: 96px;
      }
      .postContent {
        padding: 8px;
        a {
          display: inline-block;
        }
      }
      .postFooter {
        padding: 8px;
        .info {
          border: none;
          margin: 0;
        }
      }
      .voteDisplay {
        border-top: 1px solid #ccc;
      }
    }
  }

  .swot-item, .add-idea-item {
    padding: 0px;
    margin-bottom: 16px;
    border: none;
    color: #333;
    height: 150px;
    word-wrap: break-word;
    -moz-hyphens: auto;
    -o-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    border-left: rgba(255, 255, 255, 0) 8px solid;
    border-right: rgba(255, 255, 255, 0) 8px solid;
    .resourceSettingslight {
      top: unset;
      bottom: 8px;
      right: 8px;
    }
    .view {
      margin: 0;
      padding: 0;
      height: 100%;
      position: relative;
      .postHeader {
        padding: 0;
        min-height: 0;
      }
      .postContent {
        padding: 8px;
        height: calc( 100% - 32px);
        a {
          display: inline-block;
          &.add-idea {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            align-items: center;
            color: #dedede;
            &:hover {
              color: #337ab7;
            }
            &:before {
              content: "\f067";
              font-family: "Font Awesome\ 5 Free";
              font-size: 48px;
              font-weight: 900;
            }
          }
        }
      }
      .postFooter {
        padding: 8px;
        .info {
          border: none;
          margin: 0;
        }
      }
      .voteDisplay {
        border-top: 1px solid #ccc;
      }
    }
  }

  .view {
    display: inline-block;
    position: relative;
    border: 1px solid #dedede;
    padding-top: 16px;
    margin-bottom: 16px;
    -webkit-box-shadow: 3px 3px 5px 0px #dedede !important;
    -moz-box-shadow: 3px 3px 5px 0px #dedede !important;
    box-shadow: 3px 3px 5px 0px #dedede!important;
    border: 1px solid #dedede;
    &.post {
      .postHeader {
        padding: 8px;
        margin: 0;
        text-align: center;
        a {
          display: block;
        }
      }
      .postContent {
        text-align: right;
        padding: 8px;
        .labelIcon {
          background-repeat: no-repeat;
          background-size: contain;
          background-position: right;
        }
      }
      &.spidaWeb {
        display: flex;
        flex-direction: column;
        padding: 0;
        .postHeader {
          flex-grow: 1;
          background: transparent;
        }
        .postFooter {
          background: #f5f5f5;
        }
        .postRating {

        }
      }
    }

    .articleLoader{
      position: absolute;
      top: 8px;
      &.prevUrl {
        left: 8px;
      }
      &.nextUrl {
        right: 8px;
      }
    }

    .view_header{
      h2 {
        display: flex;
        flex-direction: row;
        a {
          flex-grow: 1;
        }
        .question_top {
          margin-right: 48px;
        }
      }

    }

    .view_content{
      margin-bottom: 16px;
      .attributes {
        margin: 0 16px 8px;
      }
      .view_header {
        .attributes {
          margin: 0 0 8px;
        }
      }
      .col_01 {
        img{
          width: 100%;
        }
      }
      .info {
        border-bottom: 1px solid #ccc;
        border-top: 1px solid #ccc;
        padding: 8px 0;
        margin: 8px 0;
        &>span{
          margin-right: 8px;
        }
      }
      .wrap-button-topic-list {
        margin-bottom: 16px;
        .panel {
          margin: 0 0 8px 0;
          border: none;
        }
      }
    }
  }

}