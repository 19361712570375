body {
  padding-bottom: 0px;
  line-height: 1.6;

  &.body_dynamicshowpoll{
    overflow-x: hidden;
  }
  a {
    text-decoration: none;
    border: none;
    &:focus,&:active,&:hover {
      text-decoration: none;
      border: none;
    }
  }

  .tooltips {
    text-decoration: none;
    &:hover {
      color: #426496;
      text-decoration: none;
    }
  }

  .sr-only {
    display: none;
  }

  h1 {
    font-size: 160%;
    margin-top: 0px;
    line-height: 1.2;
  }

  h2 {
    font-size: 140%;
    margin-top: 0px;
    line-height: 1.2;
  }

  h3 {
    font-size: 120%;
    margin-top: 0px;
    line-height: 1.2;
  }

  h1.subhead {
    font-size: 180%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  p {
    margin: 0 0 10px;
  }

  #wai-link {
    height: 0px;
    top: 0;

    a{
      left: -4000px;
      position: fixed;
      top: 0;
      z-index: 1;
      font-style: italic;
      color: #900;

      &:focus {
        background-color: #fff;
        display: block;
        font-size: 1em;
        left: 0;
        padding : 5px;
        padding-top: 55px;
        text-align: left;
        text-decoration: none;
        top: 0;
        width: 150px;
        z-index: 1031;
      }
    }
  }

  .cachee {
    display : none;
  }

  &>.header {
    padding: 40px;
    margin: 0px auto;
    background: #ffffff;
    text-align: left;
    width: 100%;
    max-width: 1170px;
  }

  #privacyOverlayModal {
    article {
      flex-direction: column;
    }
  }
}
