.highcharts-color-0 {
  fill: #c8e1ea;
  stroke: #c8e1ea;
}

.highcharts-color-1 {
  fill: #90c4d5;
  stroke: #90c4d5;
}
.highcharts-color-2 {
  fill: #6bb0c7;
  stroke: #6bb0c7;
}
.highcharts-color-3 {
  fill: #469cb9;
  stroke: #469cb9;
}
.highcharts-color-4 {
  fill: #387d94;
  stroke: #387d94;
}
.highcharts-color-5 {
  fill: #2a5e6f;
  stroke: #2a5e6f;
}
.highcharts-color-6 {
  fill: #1c3e4a;
  stroke: #1c3e4a;
}
.highcharts-color-7 {
  fill: #152f38;
  stroke: #152f38;
}

.highcharts-color-8 {
  fill: #0e1f25;
  stroke: #0e1f25;
}

.highcharts-color-9 {
  fill: #071012;
  stroke: #071012;
}
