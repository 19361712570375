.formresource {
  #newTopicForm #title_id > label {
    display: none;
  }
  #newTopicForm label[for="note"] {
    display: none;
  }
  .attachmentShow{
    .deleteAttachments{
      .deleteAttachmentButton{
        height: 40px;
      }
      .attachments-list{
        .attachment-list-item{
          list-style: none;
          display: flex;
          flex-direction: row;
          .attachment-list-item-inner{
            display: flex;
            flex-direction: column;
            img{
              width: auto; height: 80px;
            }
          }
        }
      }
    }
    .addAttachmentField{

    }
  }
}
