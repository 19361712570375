.breadcrumb {
  background-color: transparent;
  padding: 8px;
  margin-bottom: 16px;
  &>li {
    &+li {
      &:before {
        content: "\f054";
        font-weight: 900;
        font-family: "Font Awesome 5 Free";
      }
    }
  }
}