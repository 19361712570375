.sidebar_01 {

  h3 {
    margin-top: 0;
  }

  .more {
    display: flex;
    flex-direction: row;
    align-items: center;
    i {
      margin-right: 4px;
    }
  }

  .resourceSettings {
    position: absolute;
    top: -24px;
    z-index: 1001;
    cursor: pointer;
    right: 16px;
  }

  .mapControls {
    .legendIcon {
      font-style: normal;
      margin-right: 4px;
      float: left;
      top: 50%;
      &:before{
        font-family: "Font Awesome\ 5 Free";
        content: '\f041';
      }
      &.cat-1 {
        color: #D63E2A;
      }
      &.cat-2 {
        color: #F2942F;
      }
      &.cat-3 {
        color: #72B026;
      }
      &.cat-4 {
        color: #38AADD;
      }
      &.cat-5 {
        color: #D051B7;
      }
      &.cat-6 {
        color: #A23336;
      }
      &.cat-7 {
        color: #00619B;
      }
      &.cat-8 {
        color: #728224;
      }
      &.cat-9 {
        color: #5B396B;
      }
      &.cat-10 {
        color: #436978;
      }
      &.cat-11 {
        color: #FF8E7F;
      }
      &.cat-12 {
        color: #B9F471;
      }
      &.cat-13{
        color: #85D9FF;
      }
      &.cat-14 {
        color: #FF8DE9;
      }
    }
  }

  .miniMapElement {
    min-height: 200px;
  }

  .dash {
    width: 40px;
    background: #fff;
    float: left;
    margin-left: 5%;
    position: relative;

    .dash_title{
      color:#666666;
      font-size : 9px;
    }

    .digit {
      font-size: 16pt;
      font-weight: bold;
      text-align: center;
      font-family: Courier New;
      color: #50433e;
      padding-left: 2px;
    }
  }

  .box {
    padding: 16px;
    margin-bottom: 16px;
    border: 1px solid #ccc;

    &.latestComments{
      .content {
        &>ul {
          li {
            display: flex;
            flex-direction: column;
            .date {
              border-bottom: 1px solid #ccc;
            }
            a {
              padding: 8px 0;
            }
          }
        }
      }
    }

    .content {
      &>ul {
        list-style: none;
        padding: 0;
        &.keywords{
          text-align: center;
          li {
            display: inline-block;
            margin-right: 8px;
          }
        }
        ul {
          padding-left: 24px;
          li {
            padding:4px
          }
        }
      }
    }
  }

  .infobox {
    .panel{
      border: none;
      margin: 0;
      .panel-body {
        padding: 0;
        margin: 0;
      }
    }
  }

  .freetext {
    padding: 16px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    .attachments {
      list-style: none;
      padding-left: 8px;
      li {
        list-style:  none;
        a {
          display: block;
          padding-left: 24px;
          span {
            color: #777;
          }
        }
        &:before {
          position: absolute;
          font-family: "Font Awesome 5 Free";
          font-size: 1.5em;
        }
        &.pdf {
          &:before {
            content: "\f1c1";
          }
        }
        &.word {
          &:before {
            content: "\f1c2";
          }
        }
        &.ppt {
          &:before {
            content: "\f1c4";
          }
        }
        &.excel {
          &:before {
            content: "\f1c3";
          }
        }
        &.picture {
          &:before {
            content: "\f03e";
          }
        }
        &.zip {
          &:before {
            content: "\f1c6";
          }
        }
        &.default {
          &:before {
            content: "\f15b";
          }
        }
      }
    }
  }
}
