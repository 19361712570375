.mainContent {
  .anchors {
    padding: 8px;
  }

  .more {
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
      margin-right: 4px;
    }
  }

  .attributes {
    border-bottom: 1px solid #ccc;
    clear: both;
    margin-bottom: 8px;
    padding-bottom: 8px;

    & > span {
      border-left: 1px solid #ccc;
      margin-right: 4px;
      padding-left: 4px;

      &.first {
        border-left: none;
        padding-left: 0;
      }
    }
  }

  .resourceSettings, .resourceSettingslight {
    position: absolute;
    right: 16px;
    text-align: right;
    top: 16px;
    z-index: 1001;
    cursor: pointer;
  }

  .resourceSettingslight {
    display: flex;
    flex-direction: row;
    height: 24px;
    align-items: center;
  }

  .flexslider {
    border: 1px solid #dedede;
    padding-top: 16px;
    border-radius: 0;
  }

  .picture {
    position: relative;

    .img-copyright {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #ccc;
      color: #777;
      padding: 4px;
    }
  }

  .layout, .layout-column, .layout-row {
    flex-direction: column;

    .panel {
      border: none;
      box-shadow: none;
    }
  }

  &.formresource {
    label {
      font-weight: 400;
      padding: 0 8px;
    }

    fieldset {
      margin-bottom: 16px;
    }

    .box {
      position: relative;
      overflow: hidden;
      border: 1px solid #dedede;
      padding-top: 16px;
      margin-bottom: 16px;

      .filterLabel {
        display: flex;
        flex-direction: row;
        float: left;
        width: 24%;
        margin-right: 1%;
        align-items: center;

        input {
          margin: 0;
        }

        label {
          display: block;
          flex-grow: 1;
          padding: 8px;
          cursor: pointer;
          margin: 0;
        }
      }
    }
  }

  .header-of-content, .add-topic-top, .filter1, .filter2 {
    margin-bottom: 8px;

    .input-group {
      float: left;
      margin-right: 8px;
    }
  }

  .add-topic-top, .filter1, .filter2 {
    a {
      margin-right: 8px;
    }
  }

  .solrPagination {
    .result {
      .pagination {
        margin: 0 0 16px 0;
      }
    }
  }

  &.lukjournalshow {
    .wrap-buttons-luk {
      display: flex;
      flex-direction: row;
      margin: 16px 0 0 0;
      flex-wrap: wrap;

      .wrap-button-luk {
        display: flex;
        flex-direction: column;
        margin: 0 auto;

        .lukbutton {
          padding: 16px;
          background-color: #337ab7;
          color: #fff;
        }

        & > span {
          padding: 8px;
          text-align: center;
        }
      }
    }

    .panel {
      .panel-body {
        padding: 0;
        border: none;

        .form-catch-top-luk {
          padding: 16px;
          border: 1px solid #ccc;
        }
      }

      border: none;
    }

    .lukList {
      .lukcol {
        .rSet {
          margin-bottom: 16px;

          .box {
            padding: 16px;
            border: 1px solid #ccc;
          }
        }
      }
    }
  }
}

.social-share {
  display: flex;
  margin: 16px 0;
  .share-inner {
    margin: 0 auto;
  }
}
