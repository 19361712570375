.dynamicshowpoll {

  .polling-stage {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    min-height: 100px;

    .polling-stage-container{
      display: block;
      width: 100%;
      height: auto;
      min-height: 100px;
      position: relative;

      .polling-stage-loading{
        display: block;
        width: 100%;
        height: 75px;
        text-align: center;
        opacity: 1;
        position: absolute;
        top: 50px;

        &.ng-hide-add, &.ng-hide-remove {
          -webkit-transition:0.5s ease-in-out all;
          -moz-transition:0.5s ease-in-out all;
          transition:0.5s ease-in-out all;
        }

        &.ng-hide{
          opacity: 0;
        }
      }

      .polling-stage-inner{
        display: block;
        width: 100%;
        height: auto;
        opacity: 1;

        .polling-stage-inner-view{
          display: block;
          width: 100%;
          height: auto;
          position: relative;

          .tabs {
            display: block;
            width: 100%;
            height: auto;
            padding: 0;
            border-bottom: none;
            opacity: 1;

            &.ng-hide-add, &.ng-hide-remove {
              -webkit-transition:0.5s ease-in-out all;
              -moz-transition:0.5s ease-in-out all;
              transition:0.5s ease-in-out all;
            }

            &.ng-hide{
              opacity: 0;
            }
          }

          .polling-page {
            .polling-sections-container{
              display: block;
              width: 100%;
              height: auto;
              position: relative;

              &.slider{
                min-height: 350px;
                position: relative;
              }
            }
          }

          .polling-section {
            &.tabs {
              &.ng-hide-add{
                position: absolute;
              }
              &.ng-hide{
                opacity: 0;
                position: absolute;
              }
            }

            &.slider{
              display: block;
              width: 100%;
              height: auto;
              padding: 0;
              border: none;
              opacity: 1;
              -webkit-transition:1s linear all;
              -moz-transition:1s linear all;
              transition:1s linear all;
              &.ng-enter, &.ng-leave {
                overflow: hidden;
                position: absolute;
                max-height: 350px;
                height: 350px;
                .polling-item-wrapper {
                  opacity: 0.5;
                }
              }
            }
          }

          .left {
            .polling-section {
              &.slider{
                left:0;

                &.ng-enter{
                  left: -100%;
                }
                &.ng-enter-active{
                  left: 0;
                }

                &.ng-leave{
                  left: 0;
                }

                &.ng-leave-active{
                  left: 100%;
                }
              }
            }
          }

          .right {
            .polling-section {
              &.slider{
                right:0;

                &.ng-enter{
                  left: 100%;
                }
                &.ng-enter-active{
                  left: 0;
                }

                &.ng-leave{
                  left: 0;
                }

                &.ng-leave-active{
                  left: -100%;
                }
              }
            }
          }

          .polling-section-buttons-container{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 24px;

            .footnote {
              display: flex;
              padding: 8px;
              border-top: 1px solid #ccc;
            }

            .polling-section-buttons-container-inner{
              display: flex;
              .polling-section-buttons{
                margin-right: 8px;

                &.polling-finish-button{
                  padding-right: 16px;
                }

                .btn{
                  height: 40px;
                  line-height: 16px;
                  margin-bottom: 8px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }

          .polling-page-control {
            .polling-page-control-tabs {
              background: transparent;
              margin: 0;
              padding: 0;
              max-height: 80px;

              .polling-page-control-tabs-active{
                width: 100%;
                display: inline-block;
                height: 35px;
                background: #fefefe;
                color: #000;
                padding: 0 10px;
                line-height: 30px;
                font-size: 16px;
                font-style: inherit;
                border: 5px solid #f7f7f7;
                border-top: none;
              }

              ul{
                margin: 0;
                list-style-type: none;
                line-height: 40px;
                max-height: 40px;
                overflow: hidden;
                display: inline-block;
                display: -webkit-flex;
                display: flex;
                padding-right: 20px;
                border-bottom: 5px solid #428bca;

                li{
                  margin: 7px -10px 0;
                  -moz-border-radius-topleft: 28px 145px;
                  -webkit-border-top-left-radius: 28px 145px;
                  border-top-left-radius: 15px;
                  -moz-border-radius-topright: 28px 145px;
                  -webkit-border-top-right-radius: 28px 145px;
                  border-top-right-radius: 15px;
                  padding: 0 30px 0 25px;
                  height: 170px;
                  background: #fefefe;
                  position: relative;
                  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
                  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
                  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
                  width: auto;
                  max-width: 250px;
                  min-width: 20px;
                  border: 1px solid #ccc;

                  &:first-child {
                    margin-left: 0;
                  }

                  a{
                    display: block;
                    max-width: 100%;
                    text-decoration: none;
                    color: #222;
                    padding: 3px 7px;
                    border-color: transparent;

                    span{
                      overflow: hidden;
                      white-space: nowrap;
                      display: block;
                      text-overflow: ellipsis;
                    }
                  }

                  &.active{
                    z-index: 2;
                    background: #428bca;
                    border: 1px solid #428bca;

                    a{
                      background: transparent;
                      color: #fefefe;
                      border-color: transparent;
                    }

                  }
                }
              }
            }
          }

          .polling-section-control {
            .polling-section-control-tabs {
              background: transparent;
              margin: 0;
              padding: 0;
              max-height: 80px;

              .polling-section-control-tabs-active{
                width: 100%;
                display: inline-block;
                height: 35px;
                background: #fefefe;
                color: #000;
                padding: 0 10px;
                line-height: 30px;
                font-size: 16px;
                font-style: inherit;
                border: 5px solid #f7f7f7;
                border-top: none;
              }

              ul{
                margin: 0;
                list-style-type: none;
                line-height: 40px;
                max-height: 40px;
                overflow: hidden;
                display: inline-block;
                display: -webkit-flex;
                display: flex;
                padding-right: 20px;
                border-bottom: 5px solid #f7f7f7;

                li{
                  margin: 5px -14px 0;
                  -moz-border-radius-topleft: 28px 145px;
                  -webkit-border-top-left-radius: 28px 145px;
                  border-top-left-radius: 28px 145px;
                  -moz-border-radius-topright: 28px 145px;
                  -webkit-border-top-right-radius: 28px 145px;
                  border-top-right-radius: 28px 145px;
                  padding: 0 30px 0 25px;
                  height: 170px;
                  background: #fefefe;
                  position: relative;
                  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
                  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
                  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
                  width: 200px;
                  max-width: 200px;
                  min-width: 20px;
                  border: 1px solid #ccc;

                  &:first-child {
                    margin-left: 0;
                  }

                  a{
                    display: block;
                    max-width: 100%;
                    text-decoration: none;
                    color: #222;
                    padding: 3px 7px;
                    border-color: transparent;

                    span{
                      overflow: hidden;
                      white-space: nowrap;
                      display: block;
                      text-overflow: ellipsis;
                    }
                  }

                  &.active{
                    z-index: 2;
                    background: #428bca;
                    border: 1px solid #428bca;

                    a{
                      background: transparent;
                      color: #fefefe;
                      border-color: transparent;
                    }

                  }
                }
              }
            }

            .polling-section-control-slider{
              width: 100%;
              height: auto;
              display: inline-block;
              overflow: hidden;

              .polling-section-control-slider-inner{
                width: 100%;
                height: 40px;
                display: inline-block;
                overflow: hidden;
                border-top: 2px solid #f7f7f7;

                button {
                  width: 30px;
                  height: 30px;
                  display: inline-block;
                  vertical-align: top;
                }

                .polling-section-control-slider-list {
                  position: relative;
                  display: inline-block;
                  width: calc( 100% - 70px );
                  height: 100%;
                  vertical-align: top;
                  text-align: center;
                  line-height: 45px;
                  top: 20px;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }

                .polling-section-control-sequence-list {
                  display: flex;
                  left: 50%;
                  position: relative;
                  text-align: center;
                  top: 20px;
                  transform: translate(-50%, -50%);
                }
              }

              .polling-slider-list-dots{
                top: -5px;
                width: 11px;
                height: 11px;
                margin: 0 4px;
                position: relative;
                border-radius: 100%;
                display: inline-block;
                background-color: rgba(0, 0, 0, 0.6);
                &.active{
                  background: rgba(66,139,202, 0.6);
                }
              }

              .polling-section-control-slider-active{
                width: 100%;
                display: inline-block;
                min-height: 35px;
                background: #fefefe;
                color: #000;
                padding: 0 10px;
                line-height: 30px;
                font-size: 16px;
                font-style: inherit;
                border-top: 2px solid #f7f7f7;
                text-align: center;
              }

              .polling-slider-list-sequences {
                .info-item {

                }
                position: relative;
                display: flex;
              }

              .polling-slider-list-numbers {
                &.active {
                  .step-info {
                    color: #337ab7;
                    border-bottom: 1px solid #337ab7;
                  }
                }
                .step-info {
                  color: #ccc;
                  padding: 4px 8px;
                }
                position: relative;
                display: inline-block;
              }
            }
          }
        }

        .inner-col-wrapper {
          display: flex;
          min-height: 100%;
          flex-grow: 1;
          .polling-list-item-left {
            min-width: 32px;
            align-self: center;
            float: none;
          }

          .polling-list-item-right{
            float: none;
            text-align: left;
          }

        }

        .polling-item-wrapper {
          border-radius: 0;

          input[type=checkbox], input[type=radio] {
            clip: rect(0,0,0,0);
            pointer-events: none;
            position: absolute;
          }

          &.polling-wrapper-headline {
            border: none;
            box-shadow: none;

            .polling-item-title-wrapper {
              border: none;
              background: transparent;
              box-shadow: none;
              margin: 0;
            }

            .polling-item-content {
              &.empty-content {
                display: none;
              }

              .flex-column {
                display: flex;
                flex-direction: column;
              }

              .flex-row {
                display: flex;
                flex-direction: row;
              }
            }
          }

          &:not(.polling-wrapper-headline) {

            &.has-error {
              border-color: #a94442;

              .polling-item-title-wrapper {
                border-color: #a94442;
                background-color: #a94442;
              }
            }

            .polling-item-title-wrapper {
              background: #337ab7;
              color: #fff;
              border-color: #337ab7;
              border-radius: 0;
              margin: 0;

              h3 {
                margin: 0;
                font-weight: bold;
              }
            }

            .polling-item-content {
              position: relative;
              padding: 0;

              .flex-column {
                display: flex;
                flex-direction: column;
              }

              .flex-row {
                display: flex;
                flex-direction: row;
              }

              &.feedback {
                position: absolute;
                display: block;
                background: #fefefe;
                height: -webkit-fill-available;
                width: -webkit-fill-available;
                z-index: 100;
                overflow: hidden;

                &.ng-enter, &.ng-leave {
                  -webkit-transition: .5s linear all;
                  -moz-transition: .5s linear all;
                  -ms-transition: .5s linear all;
                  -o-transition: .5s linear all;
                  transition: .5s linear all;
                }

                &.ng-enter, &.ng-leave-active {
                  left: 100%;
                }

                &.ng-enter-active, &.ng-leave {
                  left: 0;
                }

              }

              .polling-item-description {
                height: auto;
                display: block;
                overflow: hidden;
                padding: 8px;
              }

              .polling-item-inner {
                display: block;
                height: auto;
                overflow: hidden;
                position: relative;
                padding: 8px;
              }

              table {
                margin: 0;
              }
            }
          }
          .pollingTable {
            &.polling-linear_5, &.polling-linear_7 {
              .inner-col-wrapper {
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid #ccc;
                border-right: 1px solid #ccc;
                overflow: hidden;
              }

              .d-res-table-td {
                border: 0;
                cursor: pointer;
                height: 50px;
                position: relative;
                vertical-align: middle;

                .lower-border {
                  font-weight: 700;
                  height: auto;
                  text-align: center;
                  width: 98%;

                  &:before {
                    border-bottom: 1px solid #ccc;
                    border-left: 1px solid #ccc;
                    border-right: 1px solid #ccc;
                    bottom: 0;
                    content: "";
                    height: 10px;
                    left: 0;
                    position: absolute;
                    width: 98%;
                  }
                }
              }
              .polling-group-linear-item-td {
                border: 0;
                cursor: pointer;
                height: 50px;
                position: relative;
                vertical-align: middle;

                &:before {
                  border-bottom: 3px solid #ccc;
                  content: " ";
                  left: 0;
                  position: absolute;
                  top: 50%;
                  width: 100%;
                }

                &:after {
                  border-left: 3px solid #ccc;
                  content: " ";
                  height: 8px;
                  left: calc(50% - 1px);
                  position: absolute;
                  top: calc(50% - 26px);
                  width: 98%;
                }

                .polling-radio-list-item {
                  border-bottom: none;
                  border-right: none;
                  display: block;
                  height: auto;
                  left: 50%;
                  margin: auto;
                  min-height: 30px;
                  overflow: hidden;
                  padding: 10px;
                  position: absolute;
                  transform: translate(-50%,-50%);
                }
              }
            }
          }
          .polling-radio-list, .polling-checkbox-list {
            list-style: none;
            width: 100%;
            margin: 0;
            padding: 0;
          }

          .polling-radio-list-item, .polling-checkbox-list-item{
            height: auto;
            display: block;
            overflow: hidden;
            position: relative;
            padding-bottom: 10px;
            min-height: 40px;

            &.selected {

              .polling-list-item-left {
                border-color: #428bca;
              }

              .polling-list-item-right{
                opacity: 1;
              }
            }
          }
          .polling-checkbox-group-item-td {
            &.selected, &:hover {
              .polling-checkbox-group-item-div {
                &:after {
                  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
                  filter: alpha(opacity=60);
                  opacity: 0.6;
                }
                border-color: #428bca;
              }
            }
            .polling-checkbox-group-item-div {
              &:after {
                opacity: 0;
                content: '\f00c';
                font-family: "Font Awesome 5 Free";
                font-size: 25px;
                font-weight: bold;
                color: #428bca;
                position: absolute;
                background: transparent;
                border-top: none;
                border-right: none;
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
              }
              cursor: pointer;
              position: relative;
              left: 50%;
              background: #fefefe;
              height: 35px;
              width: 35px;
              transform: translate(-50%,0%);
              border: #ccc 3px solid;
              border-radius: 5px;
            }
          }
          .polling-radio-list-item, .polling-group-linear-item-td{
            &.selected {
              .polling-list-item-left {
                background-color: #428bca;
                .polling-list-item-left-inner {
                  &:after {
                    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
                    filter: alpha(opacity=60);
                    opacity: 0.6;
                    background-color: #428bca;
                  }
                }
              }
            }
            .polling-list-item-left{
              background: #ccc;
              -webkit-border-radius: 50px;
              -moz-border-radius: 50px;
              border-radius: 50px;
              border-color: #428bca;
              position: relative;

              .polling-list-item-left-inner{
                width: 30px;
                height: 30px;
                cursor: pointer;
                position: absolute;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                border-radius: 50px;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                background: #fefefe;

                &:after{
                  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                  filter: alpha(opacity=0);
                  opacity: 0;
                  content: '';
                  position: absolute;
                  width: 24px;
                  height: 24px;
                  -webkit-border-radius: 50px;
                  -moz-border-radius: 50px;
                  border-radius: 50px;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%,-50%);
                }
              }
            }
          }

          .polling-checkbox-list-item{
            &.selected{
              .polling-list-item-left{
                &:after{
                  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
                  filter: alpha(opacity=60);
                  opacity: 0.6;
                  color:#428bca;
                }
              }
            }
            .polling-list-item-left{
              cursor: pointer;
              position: relative;
              background: #fefefe;
              border: #ccc 3px solid;
              border-radius: 4px;
              width: 32px;
              height: 32px;

              &:after{
                opacity: 0;
                content: '\f00c';
                font-family: "Font Awesome 5 Free";
                font-size: 16px;
                font-weight: 900;
                color: #aaa;
                position: absolute;
                background: transparent;
                -webkit-transform: translate(-50%, -50%);
                -moz-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
              }
            }
          }

          .polling-list-item-left,
          .polling-list-item-right{
            float: left;
          }

          .polling-list-item-left{
            width: 32px;
            height: 32px;
          }

          .polling-list-item-right{
            padding: 0 8px;
            overflow: hidden;
            opacity: 0.6;
            word-wrap: break-word;
            flex-grow: 1;

            span {
              display: block;
              height: 100%;
              padding: 8px 0;
            }
          }

        }
      }
    }
  }

  .sidebarcatcher {

  }
}

@media(hover: hover) and (pointer: fine) {
  .dynamicshowpoll {
    .polling-stage {
      .polling-stage-container{
        .polling-stage-inner {
          .polling-item-wrapper {
            .polling-radio-list-item{
              &:hover {

                .polling-list-item-left {
                  border-color: #ccc;
                }

                .polling-list-item-right{
                  opacity: 1;
                }
              }
            }
            .polling-checkbox-list-item{
              &:hover {
                .polling-list-item-right{
                  opacity: 1;
                }
              }
            }
            .polling-radio-list-item, .polling-group-linear-item-td{
              &:hover {
                .polling-list-item-left {
                  .polling-list-item-left-inner {
                    &:after {
                      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
                      filter: alpha(opacity=60);
                      opacity: 0.6;
                      background-color: #428bca;
                    }
                  }
                }
              }
            }
            .polling-checkbox-list-item {
              &:hover {
                .polling-list-item-left {
                  &:after {
                    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
                    filter: alpha(opacity=60);
                    opacity: 0.6;
                    color: #428bca;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
