/* Variables */


$linkColor: #183863;
$linkColorHover: #940d10;
$border: 1px solid #ddd;

$fontColor: #000000;
$font-sizeBig: 1.3em;

$headerBackgroundColor:#eeeeee;
$headerFontSize: 1.2em;
$headerBulletColor:#183863;


$contentBackgroundColor:#fefefe;
$contentFontSize: 1em;

$questionDescriptionFontColor:#183863;
$questionDescriptionFontSize: 24px;
$questionDescriptionFontSizeResponse: 18px;

$questionAnswerFontSize: 1em;
$questionAnswerBackground:#ffffff;
$questionAnswerBorder:1px solid #183863;
$questionAnswerColor:#183863;

$questionAnswerWrongColor: #940d10;
$questionAnswerCorrectColor: #A5CF4C;

$feedbackBackground:#ffffff;
$feedbackGenericCorrectImage: "../../../../../extension/polling/webApplication/pollingTemplateD7/polling/assets/images/quiz/rightAnswer.svg";
$feedbackGenericWrongImage:   "../../../../../extension/polling/webApplication/pollingTemplateD7/polling/assets/images/quiz/wrongAnswer.svg";
$feedbackGenericNeutralImage: "../../../../../extension/polling/webApplication/pollingTemplateD7/polling/assets/images/quiz/neutralAnswer.svg";

$footerBackgroundColor:#ffffff;
$footerHeight: 0px;

$imageContainerWidth:3;
$imageContainerHeight:2;

$imageContainerWidthResponsive:6;
$imageContainerHeightResponsive:3;

$button-border:#183863;
$button-background:#ffffff;
$button-color:#183863;



body{
	font-family: 'Open Sans', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
	-webkit-text-size-adjust: 100%;
	color: $fontColor;
}

/*Extends*/
%quizHeader{
    border-bottom: $border;
	background-color:$headerBackgroundColor;
}

%quizContent{
	display: block;
	height: calc(100% - 50px);
    min-height: 350px;
	position: relative;
	overflow: hidden;
	background-color:$contentBackgroundColor;
}

%quizFooter{
	display: none;
    height: $footerHeight;
    width: 100%;
    border-top: $border;
	background-color:$footerBackgroundColor;
}

%quizFeedbackCorrectAnswer{
    background-image: url($feedbackGenericCorrectImage);
	img[src="&medium"]{display:none;}
}
%quizFeedbackWrongAnswer{
  background-image: url($feedbackGenericWrongImage);
	img[src="&medium"]{display:none;}
}

/*Mixins*/
/* Das Größenverhältnis für den Container für Bilder bestimmen*/
@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before{
        display: block;
        content: " ";
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    > .aspect-ratio {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
		text-align:center;
    }
}



@import 'quizEditor/dialogs';
@import 'quizEditor/loading';
@import 'quizEditor/keyframes';
//Dialogs
.quiz-stage{
	height:100%;
	width:100%;
    margin: 24px auto;
    @import 'quizStage/quizStage';
    @import 'quizStage/quizQuestion';
    @import 'quizStage/quizFeedback';
	@import 'quizStage/quizResult';

}
