.main-message-wrapper-id {
  margin: 0 auto;
  position: fixed;
  top: 80px;
  right: 24px;
  z-index: 2000;
}

.joinProjectMessage {
  display: none;
  position: absolute;
  width: 250px;
  height: 75px;
  padding: 5px;
  background: #F5E427;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  color: #000;

  &:after {
    content: "";
    position: absolute;
    top: -30px;
    left: 45px;
    border-style: solid;
    border-width: 0 17px 30px;
    border-color: #F5E427 transparent;
    display: block;
    width: 0;
    z-index: 1;
  }

  .joinProjectMessageText{
    padding: 2px;
  }
}

.spinner {
  margin: 0 auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;

  &> div {
    background-color: #333;
    height: 100%;
    width: 6px;
    display: inline-block;

    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
       transform: scaleY(1.0);
       -webkit-transform: scaleY(1.0);
     }
}
