quiz-result{
    display: block;
    width: 100%;
    height: calc(100% - 50px);
    overflow: hidden;
}

.qs__quiz-result{

    &__score-container{
		padding:15px;
		margin-top:8%;
		width:50%;
		float:left;
        text-align: center;
    }
	&__image-container{
		padding:15px;
		width:50%;
		float:left;
	}
    &__score{
        display: block;
        height: auto;
    }
	&__count{
		font-size:20px;
	}
	&__your-points {
		font-weight: bold;
	}
	&__buttons {
		.btn{
			margin-top:20px;
			color: $button-background;
    		background-color: $button-border;
    		border: 2px solid $button-border;
    		border-radius: 0px;
    		-webkit-transition: all .25s ease-out;
    		transition: all .25s ease-out;
			}
		.btn:hover{
			color: $button-border;
    		background-color: $button-background;
		}
	}

}

.smiley{
    .left-eye {
        display:none;
    }
    .right-eye {
        display:none;
    }
    &-green {
		margin-top:10%;
		background-image: url($feedbackGenericCorrectImage);
		background-repeat: no-repeat;
  		background-position: top center;
		background-size: 60% 60%;
		width:100%;
		height:80%;
        .left-eye, .right-eye, .smile {
			display:none;
        }
    }
    &-yellow {
		margin-top:10%;
		background-image: url($feedbackGenericNeutralImage);
        background-repeat: no-repeat;
  		background-position: top center;
		background-size: 50% 50%;
		width:100%;
		height:80%;
        .left-eye, .right-eye, .smile {
			display:none;
        }
    }
    &-red{
		margin-top:10%;
		 background-image: url($feedbackGenericWrongImage);
        background-repeat: no-repeat;
  		background-position: top center;
		background-size: 50% 50%;
		width:100%;
		height:80%;
        .left-eye, .right-eye, .smile {
			display:none;
        }
        /*background: #ee9295;
        border: 5px solid #e27378;
        position: relative;*/
    }
	width:100%;
	height:100%;
    animation: growBox .5s;
}

/*
.smiley{
    .left-eye {
        width: 18%;
        height: 18%;
        position: relative;
        top: 29%;
        left: 22%;
        border-radius: 50%;
        float: left;
    }
    .right-eye {
        width: 18%;
        height: 18%;
        border-radius: 50%;
        position: relative;
        top: 29%;
        right: 22%;
        float: right;
    }
    &-green {
        .left-eye, .right-eye {
            background: #84b458;
        }
        .smile {
            &:before, &:after {
                content: "";
                position:absolute;
                top: -50%;
                left: 0%;
                border-radius: 50%;
                background: #84b458;
                height: 100%;
                width: 97%;
            }
            &:after {
                background: #84b458;
                height: 80%;
                top: -40%;
                left: 0%;
            }
            position: absolute;
            top :67%;
            left: 16.5%;
            width: 70%;
            height: 20%;
            overflow: hidden;
        }
        background: #a9db7a;
        border: 5px solid #92c563;
        position: relative;
    }
    &-yellow {
        .left-eye, .right-eye {
            background: #dba652;
        }
        .smile{
            position: absolute;
            top: 67%;
            left: 19%;
            width: 65%;
            height: 14%;
            background: #dba652;
            overflow: hidden;
            border-radius: 8px;
        }
        background: #eed16a;
        border: 5px solid #dbae51;
        position: relative;
    }
    &-red{
        .left-eye, .right-eye {
            background: #d96065;
        }
        .smile {
            &:before, &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0%;
                border-radius: 50%;
                background: #d96065;
                height: 100%;
                width: 97%;
            }
            &:after {
                background: #d96065;
                height: 80%;
                top: 60%;
                left: 0%;
            }
            position: absolute;
            top: 57%;
            left: 16.5%;
            width: 70%;
            height: 20%;
            overflow: hidden;
        }
        background: #ee9295;
        border: 5px solid #e27378;
        position: relative;
    }
    transition: all 1s linear;
    width: 175px;
	height: 175px;
	border-radius: 50%;
    margin: 20px auto;
    animation: growBox .5s;
}
*/

@media (min-width: 551px){
}
@media (max-width: 550px){
.qs__quiz-result{

    &__score-container{
		width:100%;
		float:none;
    }
	&__image-container{
		width:100%;
		float:none;
		max-height: 50%;
	}
    &__score{
        display: block;
        height: auto;
    }

}
}