md-dialog{
  background-color:#fefefe;
  min-width:200px;
  height:500px;
  display: flex;
  flex-direction:row;
  overflow:hidden;
  color: #000;		
		
  &.qe__dialog{

  }

  .dialog__left-area{
    height:100%;
    width: 0;
    background: #2A3F54;
  }

  .dialog__right-area{
    display:flex;
    flex-direction:column;
    flex-grow: 1;
    min-width:300px;

    .dialog__header{
      background: #ad7fa8;
      color: #fefefe;
      border-bottom: 1px solid #ccc;
      height:40px;
      min-height:40px;
      width: 100%;
      display:flex;

      .dialog__title{
        flex-grow: 1;
        color:  #fefefe;
        text-transform: uppercase;
        font-size:15px;
        line-height: 40px;
        padding-left:5px;
      }

      .dialog__header-close-button{
        width:40px;
        height:40px;
        font-size: 16px;
        line-height: 33px;
        text-align: center;
        border-left: 1px solid #ccc;
        cursor:pointer;
        &:hover{
          background-color:#fefefe;
        }
      }
    }

    .dialog__form{
      display: flex;
      width: 100%;
      flex: 1 0 0;
      min-height: calc( 100% - 80px );
    }

    .dialog__form-content{
      flex: 1 1 auto;
      display: flex;
    }

    .dialog__form-loader{
      height:100%;
      width:100%;
      position:relative;
    }

    .dialog__footer{
      border-top: 1px solid #ccc;
      height:40px;
      min-height:40px;
      display:flex;
      flex-direction: row;
      justify-content: flex-end;
      align-self: flex-end;
      width: 100%;
      overflow: hidden;
      background: #fefefe;
      >.qe__button{
        border-right: 1px solid #ccc;
        border-left: 1px solid #ccc;
        margin-top: 0;
    	margin-bottom: 0;
    	margin-right: 0;
        &:only-child{
          border-right: none;
        }
        &:last-child{
          margin-left: 30px;
          border-right:none;
        }
      }
    }
  }
}

md-select-menu{
	background: #fefefe;
	color:#000;
}

.md-select-menu-container,.md-open-menu-container,md-toast{
	z-index: 5002;
}

.md-scroll-mask{
	//background: rgba( 0, 0, 0, .25 );
	background: transparent;
	z-index: 4998;
}

md-backdrop.md-menu-backdrop{
	z-index: 4999;
}

.md-errors-spacer{
	display: none !important;
}

//Tabs & frames animations
.dialog__frames-container{
  //
}

.dialog__frame {
  &.ng-animate {
    transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
    position:absolute;
    left:0;
    right:0;
  }
}

.dialog__frame.ng-leave.ng-leave-active,
.dialog__frame.ng-enter {
  opacity:0;
}

.dialog__frame.ng-leave,
.dialog__frame.ng-enter.ng-enter-active {
  opacity:1;
}
