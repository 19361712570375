.cms {

  .picture {
    display: inline-block;
    position: relative;
  }

  .textRow {
    .panel-body {
      padding: 0;
    }
  }
  //.use-as-anchor-with-sticky-menu {
  //  display: block;
  //  position: relative;
  //  top: -160px;
  //  visibility: hidden;
  //}
  .rSet {
    &>.panel {
      margin-bottom: 8px;
    }
  }

  .faq {
    .panel-group{
      margin-bottom: 0;
    }
    .faqReply {
      display: flex;
      flex-direction: column;
      padding-left: 24px;

      .faqReply-header {
        margin-bottom: 8px;

        i {
          margin-right: 4px;
          margin-top: 2px;
        }

        h3 {
          margin: 0;
        }
      }
    }
  }

  .imageabovetext {
    .resourceSettings{
      right: 40px;
    }
    .picture {
      margin-bottom: 16px;
    }
  }

  .imagebelowtext{

  }
}