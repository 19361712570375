.qs__question {
    height: calc(100% - 50px);
	padding:0px;
	background:$contentBackgroundColor;
    &__inner{
		padding:15px;
		width:50%;
		float:left;
    }

    &__item-title {
        padding: 15px 0 0 0;
        text-align: justify;
    }

    &__item-headline {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
    }
    &__item-desc {
    }

    &__description-container{
		padding:15px;
		font-size:$questionDescriptionFontSize;
		color:$questionDescriptionFontColor;
		font-weight: 400;
    	text-align: center;
    }
    &__image-container {
		@include aspect-ratio($imageContainerWidth,$imageContainerHeight);
    }

    &__image {
        display: inline-block;
        width: 100%;
 		height: auto;
        margin: 0 auto;
    }
    &__list{
        padding:  0px;

    }
    &__list-item {
        &.selected-right {
            animation-play-state: running;
			.md-list-item-inner{
	            background-image: linear-gradient(to right, $questionAnswerCorrectColor 100%, $questionAnswerCorrectColor 100%), linear-gradient(to bottom, $questionAnswerCorrectColor 100%, $questionAnswerCorrectColor 100%), linear-gradient(to right, $questionAnswerCorrectColor 100%, #A5CF4C 100%), linear-gradient(to bottom, $questionAnswerCorrectColor 100%, $questionAnswerCorrectColor 100%);
			}
        }
        &.selected-wrong {
            animation-play-state: running;
			 .md-list-item-inner{
				    background-image: linear-gradient(to right, $questionAnswerWrongColor 100%, $questionAnswerWrongColor 100%), linear-gradient(to bottom, $questionAnswerWrongColor 100%, $questionAnswerWrongColor 100%), linear-gradient(to right, $questionAnswerWrongColor 100%, $questionAnswerWrongColor 100%), linear-gradient(to bottom, $questionAnswerWrongColor 100%, $questionAnswerWrongColor 100%);
        	}

        }
        .md-button{
           padding:0px !important;
		   animation-play-state: paused;
		   margin-bottom:10px !important;
			text-transform:none;
        }

		.md-list-item-inner{
			text-align:center;
			padding:5px;
			background: $questionAnswerBackground;
			border: $questionAnswerBorder;
			font-size: $questionAnswerFontSize;

			background-repeat: no-repeat;
        	background-size: 100% 3px, 3px 100%, 100% 3px, 3px 100%;
        	background-position: 0 0, 100% 0, 100% 100%, 0 100%;
        	animation: borderAnimationQuiz 1s cubic-bezier(0.19, 1, 0.22, 1) 1;

		}

    }
    &__option-text {
        line-height: 1.2;
		color: $questionAnswerColor
    }
    &__option-icon {
        margin: 0;
        text-align: center;
    }

    &__feedback{
        position: absolute;
        display: block;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        animation: growBox .5s;
		background:$feedbackBackground;
    }
}


.qs__question__feedback.wrong{
	.qs__feedback__image-container{
		&.noImage {
			@extend %quizFeedbackWrongAnswer;
		}
	}
	.qs__feedback__item-headline{
		color:$questionAnswerWrongColor;
	}
}

.qs__question__feedback.correct{
	.qs__feedback__image-container{
		&.noImage {
			@extend %quizFeedbackCorrectAnswer;
		}
	}
	.qs__feedback__item-headline{
		color:$questionAnswerCorrectColor;
	}
}



/*.qs__question__inner .qs__question__list:before{
		content: "Antworten:";
		font-weight:bold;
		display:block;
		margin-bottom:15px;
		font-size:$font-sizeBig;
}*/


@media (max-width: 560px){
	.qs__question {
		&__inner{
			width:100%;
			float:none;
		}
		&__description-container{
			font-size:$questionDescriptionFontSizeResponse;
    	}
		&__image-container {
			@include aspect-ratio($imageContainerWidthResponsive,$imageContainerHeightResponsive);
    	}
	}
}
