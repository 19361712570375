#mainnav {
  &.sticky {
    position: fixed;
    top: 50px;
    width: 100%;
    -webkit-transition: top 1s; /* Safari */
    transition: top 1s;
  }
  &.navbar {
    &.navbar-default {
      .hidden-sub-menu-item, .sequence-steps {
        display: none;
      }
      .nav {
        &.navbar-nav {
          float: none;
          margin: 0 auto;
          text-align: center;
          width: 100%;
          &>li {
            display: inline-block;
            float: none;
            padding-left: 10px;
            padding-right: 10px;
          }
          .nav-item {
            &.active {
              &>a {
                color: #337ab7
              }
              .btn-group {
                &>a {
                  color: #337ab7
                }
              }
            }
            &:not(.active){
              .btn-group {
                &>a {
                  color: #777
                }
              }
            }
            &.dropdown {
              .btn-group {
                display: block;
                &>a {
                  &:hover {
                    color: #337ab7;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.dito-mobile-menu-toggle {
  display: none;
  position: fixed;
  top: 0;
  right: 8px;
  z-index: 1031;

  .dito-mobile-menu-button {
    &.btn{
      &.navbar-toggle{
        background: transparent;
        color: #2f2f2f;
        border: 1px solid #ccc;
        width: 40px;
        height: 40px;
        box-shadow: none;
      }
    }


    &:after {
      display:none;
    }

    .icon-bar {
      background: #ccc;
      height: 4px;
      margin-bottom: 4px;
      width: 20px;
    }

  }
}

.dito-mobile-menu {
  &.open {
    display: flex;
    flex-direction: column;
    padding-right: 0;
  }
  .modal-content {
    top: 0;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    overflow: auto;
  }

  .nav {
    &.mainnavigation {
      display: flex;
      flex-direction: column;

      .main-link {
        &.login-link{
          border-bottom: 1px solid #ccc;
        }
        & > .nav {
          & > .nav-item {
           border-bottom: 1px solid #ccc;

            &.active {
              .headline {
                color: #337ab7;
              }
            }
          }
        }

        .headline {
          padding: 0;
          font-size: 1.2em;
          text-transform: uppercase;
          font-weight: bold;
          color: #2f2f2f;
          margin-bottom: 25px;
          /*     padding-left: 10px; */
        }

        .second-level {
          list-style-type: none;
          padding-left: 16px;
          padding-right: 16px;

          li:not(.nav-item) {
            padding: 8px;
            a {
              color: #2f2f2f;
            }
          }
          .nav-item {
            margin: 0 16px;

            a {
              color: #2f2f2f;
              display: flex;
              font-size: .87em;
              padding: 8px;
              border-bottom: 1px solid #ccc;
            }
          }
        }
      }



      .second-link {
        ul {
          position: absolute;
          display: flex;
          flex-direction: column;
          float: left;
          left: 24px;
          width: calc(100% - 24px);
        }

        a {
          text-transform: uppercase;
          color: #2f2f2f;
          font-weight: bold;
          font-size: 1.2em;
        }
      }

      a {
        width: 100%;
        padding-left: 24px;

        &:hover {
          background: none;
        }
      }
    }
  }

  .close {
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 100;
    &:before {
      font-family: "Font Awesome\ 5 Free";
      content: "\f00d";
      font-weight: 900;
    }
  }
}

@media ( max-width : 992px) {
  .toggle-button.menu, .toggle-button.menu button#nav-main-hamburger{
    display: block;
  }
}
@media ( max-width : 769px) {
  /*Navigationen nebeneinander#############*/
  #mainnav.navbar-default,#topnav.navbar-inverse {
    display: none;
    background: #000000;
    color: #ffffff;
    border: none;
  }
  #topnav.navbar-inverse {
    width: 50%;
    z-index: 1035;
  }
  #mainnav.navbar-default {
    width: 100%;
    position: fixed;
    top: 0;
    /*left:50%;*/
    z-index: 1030;
  }
  #topnav .navbar-brand {
    display: none;
  }
  #topnav.navbar-inverse .navbar-toggle {
    float: left;
    left: 15px;
    position: absolute;
    z-index: 10000;
    display: block;
  }
  #mainnav.navbar-default .navbar-toggle {
    float: right;
    right: 0;
    position: absolute;
    z-index: 10001;
    border-color: #333;
    display: block;
  }
  #mainnav.navbar-default .navbar-toggle:hover {
    background-color: #333333;
  }
  #mainnav .navbar-brand {
    display: none;
  }
  #topnav .navbar-collapse,#mainnav .navbar-collapse {
    max-height: 340px;
    position: absolute;
    z-index: 20000;
    padding-right: 3%;
    padding-left: 3%;
    overflow-x: hidden;
    overflow-y: visible;
    border: 1px solid #cccccc;
    top: 48px;
    background: #ffffff !important;
    width: 100%;
  }
  #mainnav .navbar-collapse {
    width: 50%;
    left: 50%;
  }

  /*

  #mainnav .navbar-nav>li {
      float: none;
      display: inline;
      padding-left: 0px;
      padding-right:0px;
  }
  */
  #mainnav.navbar-default .navbar-nav>li>a,#topnav.navbar-inverse .navbar-nav>li>a
  {
    color: #333333;
    border-bottom: 1px solid #cccccc;
  }
  #mainnav.navbar-default .navbar-nav>li>a:hover,
  #topnav.navbar-inverse .navbar-nav>li>a:hover {
    color: #000000;
  }
  #topnav.navbar-inverse .navbar-nav>.active>a,#topnav.navbar-inverse .navbar-nav>.active>a:hover,#topnav.navbar-inverse .navbar-nav>.active>a:focus,#mainnav.navbar-default.navbar-nav>.active>a,#mainnav.navbar-default.navbar-nav>.active>a:hover,#mainnav.navbar-default.navbar-nav>.active>a:focus
  {
    color: #555;
    background-color: #e7e7e7;
  }
  #mainnav.navbar-default .navbar-nav>.open>a,#mainnav.navbar-default .navbar-nav>.open>a:hover,#mainnav.navbar-default .navbar-nav>.open>a:focus,#topnav.navbar-inverse .navbar-nav>.open>a,#topnav.navbar-inverse .navbar-nav>.open>a:hover,#topnav.navbar-inverse .navbar-nav>.open>a:focus
  {
    color: #fff;
    background-color: #080808;
  }
  #mainnav.navbar-default .navbar-nav .open .dropdown-menu>li>a,#topnav.navbar-inverse .navbar-nav .open .dropdown-menu>li>a
  {
    color: #333;
  }
  #mainnav.navbar-default .navbar-nav .open .dropdown-menu>li>a:hover,#topnav.navbar-inverse .navbar-nav .open .dropdown-menu>li>a:hover
  {
    color: #000;
  }
  .navbar-default .navbar-toggle .sr-only,.navbar-inverse .navbar-toggle .sr-only
  {
    display: none !important;
  }
  #mainnav.navbar-default .navbar-collapse {
    padding: 0;
  }
  #mainnav.navbar-default .navbar-collapse .navbar-nav li a,
  #mainnav.navbar-default .navbar-collapse .navbar-nav >li>a {
    text-align: left;
    color: #333;
  }
  /* 	body { */
  /* 		padding-top: 60px; */
  /* 	} */
  /* following is crap we want to display this menu */
  /* 	.navbar-collapse.collapse { */
  /* 		display: none !important; */
  /* 		height: auto !important; */
  /* 		padding-bottom: 0; */
  /* 		overflow: hidden !important; */
  /* 	} */
  .navbar-nav {
    margin: 0;
    margin-left: -15px;
    margin-right: -15px !important;
    float: none;
    padding: 0;
  }
  .navbar-collapse .navbar-nav.navbar-right {
    margin-right: 0;
    float: none !important;
  }
  #mainnav .nav>li,#topnav .nav>li {
    position: relative;
    display: block;
    width: 100%;
    float: none;
    margin: 0;
    padding-left: 0;
  }
  #mainnav .navbar-nav>li>a,#topnav .navbar-nav>li>a {
    line-height: 20px;
    position: relative;
    display: block;
    padding: 10px 15px;
  }
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }

  /*Navigationen nebeneinander ende#############*/
  .header .logo img {
    width: 100%;
    height: auto;
  }
  .header {
    border-bottom: 1px solid #cccccc;
    margin-bottom: 5px;
    background-size: 10%;
  }
  ol.breadcrumb {
    display: none !important;
  }
  .dito-mobile-menu {
    .nav {
      &.mainnavigation {
        .main-link {
          .headline {
            font-size: inherit;

            i {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}

@media ( max-width : 480px) {
  .breadcrumb {
    display: none;
  }

}