.header {
  .logo{
    img {
      width: auto;
    }
  }
  .project-title {
    & > .title {
      font-size: 36px;
    }
  }
}

@media (max-width: 768px){
  .header {
    .logo {
      text-align: center;
      img {
        max-width: 250px;
      }
    }
    .project-title {
      padding: 16px;
    }
  }
}