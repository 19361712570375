#topnav {
  padding: 0 16px;
  .pencilHider {
    min-height: 50px;
    background-color: transparent;
    border: none;
    min-width: 52px;
    color: #9d9d9d;
    outline: none;
    &:hover {
      color: #fff;
    }
    .fa-edit {
      margin-left: 2px;
    }
  }
}

//.blackbar .navbar-nav >li.icon >button , .blackbar .navbar-nav >li.icon >button:hover{
//  background: none !important;
//  border: none !important;
//  color: #ccc;
//  margin-top: 5px;
//}