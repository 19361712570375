@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~highcharts/css/highcharts.css';
@import '~ekko-lightbox/dist/ekko-lightbox.css';
@import '~leaflet/dist/leaflet.css';
@import '~angular-material/angular-material.min.css';
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';
@import '~leaflet.locatecontrol/dist/L.Control.Locate.min.css';
@import '~leaflet.fullscreen/Control.FullScreen.css';
//@import '~l.control.rose/dist/L.Control.Rose.css';
@import '~flexslider/flexslider.css';
@import '../scss/libs/leaflet/leaflet.rrose';
@import '../css/cssIncludes/cssLibs/bootstrap-switch.css';
@import '../js/lib/shariff/shariff.min.css';

@import '../css/cssIncludes/fonts/ontopica_font/css/ontopica.css';
@import '../css/cssIncludes/fonts/ontopica_font/css/animation.css';

@import 'libs/uiTree';

@import './general/default';
@import '../../../../../extension/polling/webApplication/pollingTemplateD7/polling/assets/scss/quiz.scss';
@import '../css/cssIncludes/cssModules/solr.css';
